import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import useSiteMetadata from "../components/SiteMetadata";

export const PicturesPostTemplate = ({
  tags,
  title,
  helmet,
  image
}) => {
  const fixedImage = image && image.childImageSharp.fixed ? image.childImageSharp.fixed : { width: 1110 };
  
  return (
    <div className="uk-grid">
      {helmet || ''}
      <div className="uk-width-1-1">
        <div className="main uk-width-auto section" id="main">
          <div className="widget Blog" data-version="2" id="Blog1">
            <div className="single_post hfeed" id="single_post">
              <article className="post hentry">
                <div className="post_content">
                  <h1 className="post_title entry-title uk-article-title">
                    {title}
                  </h1>
                  <div className="post_body entry-content uk-margin-large-bottom" id="post_body_6501734076252104728">
                    {
                      image && image.childImageSharp.fluid && image.childImageSharp.fixed && fixedImage.width < 1110
                        ? <img
                          src={image.childImageSharp.fluid.src}
                          alt={title}
                          width={fixedImage.width}
                          height={fixedImage.height}
                          style={{ borderRadius: '5px' }}
                        />
                        : <PreviewCompatibleImage
                          imageInfo={{
                            image: image,
                            alt: title,
                          }}
                        />
                    }
                  </div>
                  {tags && tags.length ? (
                    <div className="post_footer uk-article-meta">
                      <div className="post_footer_line post_footer_line_1">
                        <span className="post_labels uk-margin-right uk-margin-small-bottom">
                          <span className="post_labels_label uk-margin-xsmall-right">
                            <span className="uk-icon uk-margin-small-right" data-uk-icon="icon:tag;ratio:.75">
                              <svg width="15"
                                   height="15"
                                   viewBox="0 0 20 20"
                                   xmlns="http://www.w3.org/2000/svg"
                                   data-svg="tag"><path
                                fill="none" stroke="#000" stroke-width="1.1"
                                d="M17.5,3.71 L17.5,7.72 C17.5,7.96 17.4,8.2 17.21,8.39 L8.39,17.2 C7.99,17.6 7.33,17.6 6.93,17.2 L2.8,13.07 C2.4,12.67 2.4,12.01 2.8,11.61 L11.61,2.8 C11.81,2.6 12.08,2.5 12.34,2.5 L16.19,2.5 C16.52,2.5 16.86,2.63 17.11,2.88 C17.35,3.11 17.48,3.4 17.5,3.71 L17.5,3.71 Z"></path><circle
                                cx="14" cy="6" r="1"></circle></svg></span>Štítky</span>
                          {tags.map((tag, index) => (
                            <>
                              <Link to={`/tags/${kebabCase(tag)}/`} rel="tag" key={tag + `tag`}>#{tag}</Link>
                              {index < (tags.length - 1) && <span className='uk-margin-xsmall-right'>,</span>}
                            </>
                          ))}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </article>
            </div>
            <div className='blog_pager uk-text-center uk-margin-large-top'>
              <Link className='blog-pager-older-link uk-button uk-button-default'
                    to='/obrazky' title='Prohlédnout další obrázky'>
                <span className='uk-margin-small-right'>Prohlédnout další obrázky</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PicturesPostTemplate.propTypes = {
  title: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

const PicturesPost = ({ data, location }) => {
  const { host } = useSiteMetadata();
  const { markdownRemark: post } = data;

  const metaTitle = `${post.frontmatter.title} | Obrázky | Angličtina s Pájou`;
  const metaUrl = `${host}${location.pathname}`;
  const metaImagePath = `${host}${post.frontmatter.ogimage.childImageSharp.fluid.src}/`;

  return (
    <Layout>
      <PicturesPostTemplate
        helmet={
          <Helmet>
            <title>{metaTitle}</title>
            <link rel="canonical" href={metaUrl} />
            <meta name="description" content=""/>
            <meta name="image" content={metaImagePath} />
            <meta property="og:title" content={metaTitle}/>
            <meta property="og:description" content=""/>
            <meta property="og:url" content={metaUrl}/>
            <meta property="og:image" content={metaImagePath}/>
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content="" />
            <meta name="twitter:image" content={metaImagePath} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage}
      />
    </Layout>
  )
}

PicturesPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PicturesPost

export const pageQuery = graphql`
  query PicturesPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1110, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 1110, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ogimage: featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
